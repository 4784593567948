import React, { useEffect } from 'react';

import Sort from '../../components/SortComponent/Sort';

import image from '../../assets/img/selection-sort.png';

const SelectionSort = () => {
  const code = 'function selectionSort(arr) {\n'+
'  let min;\n'+
'\n'+
'  //start passes.\n'+
'  for (let i = 0; i < arr.length; i++) {\n'+
'    //index of the smallest element to be the ith element.\n'+
'    min = i;\n'+
'\n'+
'    //Check through the rest of the array for a lesser element\n'+
'    for (let j = i + 1; j < arr.length; j++) {\n'+
'      if (arr[j] < arr[min]) {\n'+
'        min = j;\n'+
'      }\n'+
'    }\n'+
'\n'+
'    //compare the indexes\n'+
'    if (min !== i) {\n'+
'      //swap\n'+
'      [arr[i], arr[min]] = [arr[min], arr[i]];\n'+
'    }\n'+
'  }\n'+
'\n'+
'  return arr;\n'+
'}\n'+
'\n'+
'console.log(selectionSort([29, 72, 98, 13, 87, 66, 52, 51, 36]));\n';


  useEffect(() => {

  }, []);

  return (
    <main className="col-12">
            <h1 className="bd-title">Selection Sort</h1>
            <Sort method="quickSort" />

            <p className="bd-lead">Selection sort uses the recursion technique. In the guide below, we are using ascending order. For descending order, you do the reverse.</p>
            <h4 className="">Step-by-step guide:</h4>
            <ol>
              <li>Start with the first element in the array.</li>
              <li>Compare it with the element to its right.</li>
              <li>If the element to its right is less than it, swap them.</li>
              <li>Repeat steps 2 and 3 until the element is in the correct position.</li>
              <li>Repeat steps 1-4 until the array is sorted.</li>
            </ol>
            <h4>Image representation</h4>
            <img src={image} alt="Bubble Sort" />
            <h4>JavaScript implementation</h4>
            <div className="highlight">
                <pre>
                <code>
                    {code}               
                </code>
                </pre>
            </div>


        </main>
        
  );
};

export default SelectionSort;