import React from 'react';
import { useSelector } from 'react-redux';

const LoadingScreen = () => {

  const { loading } = useSelector((state) => state.globalReducer);
  console.log(loading);

  return (
    <div></div>
  );
};

export default LoadingScreen;