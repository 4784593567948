
import bootstrapLogo from '../../assets/img/bootstrap-4.svg';
import './style.css';

const Footer = () => { 

    return (
        <footer class="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">

                        <img src={bootstrapLogo} alt="logo" className="small-logo inline" />
                        <h4 className="inline">Bootstrap</h4>
                        <p>Designed and built with all the love in the world by the Bootstrap team with the help of our
                            contributors.</p>
                        <p>Code licensed MIT, docs CC BY 3.0.</p>
                        <p>Currently v5.0.2.</p>
                    </div>
                    <div className="col-md-4">
                        <h4>Page links</h4>
                        <a href="/">Home</a>
                        <a href="#id1">Step 1</a>
                        <a href="#id2">Step 2</a>
                    </div>
                    <div class="col-md-4">
                        <h4>Bibliography</h4>
                        <a href="http://google.ro" target="_blank" rel="noreferrer">Google</a>
                        <a href="https://www.w3schools.com/html/default.asp" target="_blank" rel="noreferrer">Documentation 1</a>
                        <a href="https://www.section.io/engineering-education/sorting-algorithms-in-js/" target="_blank" rel="noreferrer">Sorting Algorithms</a>
                    </div>
                </div>
            </div>
        </footer>
            
    )
}

export default Footer;