import React, { useEffect } from 'react';

import Sort from '../../components/SortComponent/Sort';

import image from '../../assets/img/merge_sort.png';

const MergeSort = () => {
  const code = '//merging two arrays appropriately.\n'+
'function merge(arr1, arr2) {\n'+
'  //make a new array and have two value pointers\n'+
'  let res = [],\n'+
'    i = 0,\n'+
'    j = 0;\n'+
'\n'+
'  //sorting the first array.\n'+
'  if (arr1.length > 1) {\n'+
'    let min = 0;\n'+
'    for (let i = 0; i < arr1.length; i++) {\n'+
'      if (i !== min) {\n'+
'        if (arr1[i] < arr1[min]) {\n'+
'          //also swap the elements\n'+
'          [arr1[i], arr1[min]] = [arr1[min], arr1[i]];\n'+
'          //change the minimum\n'+
'          min = i;\n'+
'        }\n'+
'      }\n'+
'    }\n'+
'  }\n'+
'\n'+
'  //sorting the second array.\n'+
'  if (arr2.length > 1) {\n'+
'    let min = 0;\n'+
'    for (let i = 0; i < arr2.length; i++) {\n'+
'      if (i !== min) {\n'+
'        if (arr2[i] < arr2[min]) {\n'+
'          //also swap the elements\n'+
'          [arr2[i], arr2[min]] = [arr2[min], arr2[i]];\n'+
'          //change the minimum\n'+
'          min = i;\n'+
'        }\n'+
'      }\n'+
'    }\n'+
'  }\n'+
'\n'+
'  //Value comparison.\n'+
'  while (i < arr1.length && j < arr2.length) {\n'+
'    if (arr1[i] < arr2[j]) {\n'+
'      res.push(arr1[i]);\n'+
'      i++;\n'+
'    } else {\n'+
'      res.push(arr2[j]);\n'+
'      j++;\n'+
'    }\n'+
'  }\n'+
'\n'+
'  //pushing the rest of arr1.\n'+
'  while (i < arr1.length) {\n'+
'    res.push(arr1[i]);\n'+
'    i++;\n'+
'  }\n'+
'\n'+
'  //pushing the rest of arr2.\n'+
'  while (j < arr2.length) {\n'+
'    res.push(arr2[j]);\n'+
'    j++;\n'+
'  }\n'+
'\n'+
'  return res;\n'+
'}\n'+
'\n'+
'//merge sort\n'+
'function mergeSort(arr) {\n'+
'  //Best case\n'+
'  if (arr.length <= 1) return arr;\n'+
'\n'+
'  //splitting into halves\n'+
'  let mid = Math.ceil(arr.length / 2);\n'+
'\n'+
'  let arr1 = arr.slice(0, mid);\n'+
'\n'+
'  let arr2 = arr.slice(mid);\n'+
'\n'+
'  let arr1_subarrays = [],\n'+
'    sorted_arr1_subarrays = [];\n'+
'\n'+
'  let arr2_subarrays = [],\n'+
'    sorted_arr2_subarrays = [];\n'+
'\n'+
'  //loop through array 1 making subarrays of two elements\n'+
'  for (let i = 0; i < arr1.length; i += 2) {\n'+
'    arr1_subarrays.push(arr1.slice(i, i + 2));\n'+
'  }\n'+
'\n'+
'  //loop through array 2 making subarrays of two elements.\n'+
'  for (let i = 0; i < arr2.length; i += 2) {\n'+
'    arr2_subarrays.push(arr2.slice(i, i + 2));\n'+
'  }\n'+
'\n'+
'  // sorting each subarray of arr1.\n'+
'  for (let i = 0; i < arr1_subarrays.length; i += 2) {\n'+
'    let result = merge(arr1_subarrays[i], arr1_subarrays[i + 1]);\n'+
'    result.forEach((value) => sorted_arr1_subarrays.push(value));\n'+
'  }\n'+
'\n'+
'  // sorting each subarray of arr2.\n'+
'  for (let i = 0; i < arr2_subarrays.length; i += 2) {\n'+
'    let result = merge(arr2_subarrays[i], arr2_subarrays[i + 1]);\n'+
'    result.forEach((value) => sorted_arr2_subarrays.push(value));\n'+
'  }\n'+
'\n'+
'  let result = merge(sorted_arr1_subarrays, sorted_arr2_subarrays);\n'+
'\n'+
'  return result;\n'+
'}\n'+
'\n'+
'console.log(mergeSort([70, 50, 30, 10, 20, 40, 60]));\n';


  useEffect(() => {

  }, []);

  return (
    <main className="col-12">
            <h1 className="bd-title">Merge Sort</h1>
            <Sort method="quickSort" />

            <p className="bd-lead">Merge sort uses the divide and conquer technique. The main concept of merge sort is that an array of length 1 is sorted. The task, therefore, lies in splitting the array into subarrays of size 1 and then merge them appropriately so that it comes up with the sorted array.</p>
            <h4 className="">Step-by-step guide:</h4>
            <ol>
              <li>Split the array into two halves.</li>
              <li>Compare the individual elements and arrange them in order. This results in subarrays of length 1 or 2.</li>
              <li>Make an empty array.</li>
              <li>Compare the elements of the subarrays and push the smaller of the values to the empty array.</li>
              <li>If you had extracted all the values from one of the arrays, push the remaining array to the new array.</li>
              <li>Continue until all subarrays have been covered and you have one sorted array.</li>

            </ol>
            <h4>Image representation</h4>
            <img src={image} alt="Bubble Sort" />
            <h4>JavaScript implementation</h4>
            <div className="highlight">
                <pre>
                <code>
                    {code}               
                </code>
                </pre>
            </div>


        </main>
        
  );
};

export default MergeSort;