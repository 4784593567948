import { quickSort, bubbleSort, insertionSort, selectionSort, mergeSort } from "../../helpers/sortHelper";
import React, { useState } from "react";
import "./style.css";

const Sort = (props) => { 

    const { method } = props;

    const [result, setResult] = useState("");

    const sort = () => { 
        const input = document.querySelector("input").value;
        const arr = input.split(" ").map((num) => parseInt(num));
        let result = [];
            
            switch (method) {
                case "quickSort":
                    result = quickSort(arr);
                    break;
                case "bubbleSort":
                    result = bubbleSort(arr);
                    break;
                case "insertionSort":
                    result = insertionSort(arr);
                    break;
                case "selectionSort":
                    result = selectionSort(arr);
                    break;
                case "mergeSort":
                    result = mergeSort(arr);
                    break;
                default:

                    result = quickSort(arr);
                    break;
            }
            setResult(result.join(" "));
    }

    
    return (
        <div className="sort">
            <div className="row">
                <div className="col-12">
                    <div className="form-group">
                        <label>Enter a set of numbers</label>
                        <input type="text" className="form-control"/>
                        <small></small>
                    </div>
                    <br></br>
                    <button className="btn btn-primary" onClick={sort}>Sort</button>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="highlight">
                                <pre>Result:<br></br>
                                    <code>
                                        {result}
                                    </code>
                                </pre>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );

}

export default Sort;