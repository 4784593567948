import React, { useState } from 'react';

const DateTime = () => {
    const dateCSS = {
        margin: "0",
    }

    const [date, setDate] = useState(new Date());
    setInterval(() => { 
        setDate(new Date());
    }, 1000);


  return (
    <div>
        <p className="date-time" style={dateCSS}>Time: {date.toLocaleTimeString()}</p>
        <p className="date-time" style={dateCSS}>Date: {date.toLocaleDateString()}</p>
    </div>
    );
};

export default DateTime;