import React, { useEffect } from 'react';

const Dashboard = () => {


  useEffect(() => {

  }, []);

  return (
    <main className="col-12">
      <h1 className="bd-title">Sorting Algorithms in JavaScript</h1>

      <p className="bd-lead">Sorting can be referred to as arranging files in some particular order. The arrangement performed can be based on the value of each file present. That particular order can be in either an ascending or descending fashion. Sorting algorithms are instructions given to a computer to arrange elements in a particular order.</p>
      <h4 className="">Categories of sorting algorithms:</h4>
      <p>There are two main categories of sorting algorithms:</p>
      <ol>
        <li><strong>Internal sorting</strong> These are sorting algorithms applied to a small amount of data. Only the main memory is used. Examples would be bubble sort, insertion sort, and quicksort.</li>
        <li><strong>External sorting</strong> These are sorting algorithms applied to a large amount of data. The main memory is not enough to hold all the data. Examples would be merge sort, heapsort, and radix sort.</li>
      </ol>
      <h4>Efficiency of sorting algorithms</h4>
      <p>Some sorting algorithms are more efficient than others. The effectiveness of a sorting algorithm is usually defined by the following performance measures:</p>
      <ol>
        <li><strong>Time complexity</strong> This is the amount of time taken by the algorithm to sort a given set of data.</li>
        <li><strong>Space complexity</strong> This is the amount of memory space required by the algorithm to sort a given set of data.</li>
      </ol>
      <h4>Time complexity</h4>
      <p>Time complexity is the amount of time taken by the algorithm to sort a given set of data. It is usually represented by the letter T(n) where n is the number of elements in the data set. The time complexity of an algorithm is usually represented by the following notations:</p>
      <ol>
        <li><strong>Big O notation</strong> This is the upper bound of the time complexity of an algorithm. It is usually represented by the letter O(n).</li>
        <li><strong>Big Omega notation</strong> This is the lower bound of the time complexity of an algorithm. It is usually represented by the letter Ω(n).</li>
        <li><strong>Big Theta notation</strong> This is the exact time complexity of an algorithm. It is usually represented by the letter Θ(n).</li>
      </ol>
      <h4>Space complexity</h4>
      <p>Space complexity is the amount of memory space required by the algorithm to sort a given set of data. It is usually represented by the letter S(n) where n is the number of elements in the data set. The space complexity of an algorithm is usually represented by the following notations:</p>
      <ol>
        <li><strong>Big O notation</strong> This is the upper bound of the space complexity of an algorithm. It is usually represented by the letter O(n).</li>
        <li><strong>Big Omega notation</strong> This is the lower bound of the space complexity of an algorithm. It is usually represented by the letter Ω(n).</li>
        <li><strong>Big Theta notation</strong> This is the exact space complexity of an algorithm. It is usually represented by the letter Θ(n).</li>
      </ol>
      <h4>Strategies applied during sorting</h4>
      <p>There are two main strategies applied during sorting:</p>
      <ol>
        <li><strong>Comparison-based sorting</strong> This is a sorting strategy where the elements are compared to each other. Examples would be bubble sort, insertion sort, and quicksort.</li>
        <li><strong>Non-comparison-based sorting</strong> This is a sorting strategy where the elements are not compared to each other. Examples would be counting sort, radix sort, and bucket sort.</li>
      </ol>
      <h4>Comparison-based sorting</h4>
      <p>Comparison-based sorting is a sorting strategy where the elements are compared to each other. Examples would be bubble sort, insertion sort, and quicksort.</p>
      <h4>Non-comparison-based sorting</h4>
      <p>Non-comparison-based sorting is a sorting strategy where the elements are not compared to each other. Examples would be counting sort, radix sort, and bucket sort.</p>
      
    </main>
  );
};

export default Dashboard;