import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  Dashboard,
  QuickSort,
  BubbleSort,
  SelectionSort,
  InsertionSort,
  MergeSort,
} from '../modules';
import { Navigation, Footer, LoadingScreen } from '../components';



const AppRoute = () => (
    <BrowserRouter>
        
    <Navigation />
    <div className='container'>
      <div className='row'>
      <Routes>
        
          
        <Route exact path="/" element={<Dashboard />} />

        <Route
          exact
          path="/quick-sort"
          element={<QuickSort />}
        />
        <Route
          exact
          path="/bubble-sort"
          element={<BubbleSort />}
          />
        <Route
          exact
          path="/insertion-sort"
          element={<InsertionSort />}
          />
        <Route
          exact
          path="/selection-sort"
          element={<SelectionSort />}
          />
        <Route
          exact
          path="/merge-sort"
          element={<MergeSort />}
          />
          
        </Routes>
      </div>
    </div>
    
    <Footer />
  </BrowserRouter>
);

export default AppRoute;