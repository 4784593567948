
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import AppRoute from './routes';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';




const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <AppRoute />
  </StrictMode>
);