import Sort from '../../components/SortComponent/Sort';
import image from '../../assets/img/bubble-sort.png';


const BubbleSort = () => {

    const str = 'function bubbleSort(arr){\n' +
        '\n' +
        '    //Outer pass\n' +
        '    for(let i = 0; i < arr.length; i++){\n' +
        '\n' +
        '        //Inner pass\n' +
        '        for(let j = 0; j < arr.length - i - 1; j++){\n' +
        '\n' +
        '            //Value comparison using ascending order\n' +
        '\n' +
        '            if(arr[j + 1] < arr[j]){\n' +
        '\n' +
        '                //Swapping\n' +
        '                [arr[j + 1],arr[j]] = [arr[j],arr[j + 1]]\n' +
        '            }\n' +
        '        }\n' +
        '    };\n' +
        '    return arr;\n' +
        '};\n' +
        '\n' +
        'console.log(bubbleSort([5,3,8,4,6]));\n';

    
    return (
        <main className="col-12">
            <h1 className="bd-title">Bubble Sort</h1>
            <Sort method="bubbleSort" />

            <p className="bd-lead">Bubble sort follows the recursion technique.</p>
            <h4 className="">Step-by-step guide:</h4>
            <ol>
                <li>Compare the first two elements of the array.</li>
                <li>If the first element is greater than the second element, swap them.</li>
                <li>Compare the second and third elements. Swap them if they are not in order.</li>
                <li>Continue comparing the elements until the last two elements of the array are compared.</li>
                <li>Repeat steps 1-4 until the array is sorted.</li>
            </ol>
            <h4>Image representation</h4>
            <img src={image} alt="Bubble Sort" />
            <h4>JavaScript implementation</h4>
            <div className="highlight">
                <pre>
                <code>
                    {str}               
                </code>
                </pre>
            </div>


        </main>
        
    );

 }

export default BubbleSort;