import React, { useEffect } from 'react';

import Sort from '../../components/SortComponent/Sort';

import image from '../../assets/img/quick-sort.png';

const QuickSort = () => {
  const code = 'function partition(items, left, right) {\n'+
'  //rem that left and right are pointers.\n'+
'\n'+
'  let pivot = items[Math.floor((right + left) / 2)],\n'+
'    i = left, //left pointer\n'+
'    j = right; //right pointer\n'+
'\n'+
'  while (i <= j) {\n'+
'    //increment left pointer if the value is less than the pivot\n'+
'    while (items[i] < pivot) {\n'+
'      i++;\n'+
'    }\n'+
'\n'+
'    //decrement right pointer if the value is more than the pivot\n'+
'    while (items[j] > pivot) {\n'+
'      j--;\n'+
'    }\n'+
'\n'+
'    //else we swap.\n'+
'    if (i <= j) {\n'+
'      [items[i], items[j]] = [items[j], items[i]];\n'+
'      i++;\n'+
'      j--;\n'+
'    }\n'+
'  }\n'+
'\n'+
'  //return the left pointer\n'+
'  return i;\n'+
'}\n'+
'\n'+
'function quickSort(items, left, right) {\n'+
'  let index;\n'+
'\n'+
'  if (items.length > 1) {\n'+
'    index = partition(items, left, right); //get the left pointer returned\n'+
'\n'+
'    if (left < index - 1) {\n'+
'      //more elements on the left side\n'+
'      quickSort(items, left, index - 1);\n'+
'    }\n'+
'\n'+
'    if (index < right) {\n'+
'      //more elements on the right side\n'+
'      quickSort(items, index, right);\n'+
'    }\n'+
'  }\n'+
'\n'+
'  return items; //return the sorted array\n'+
'}\n'+
'\n'+
'let items = [5, 3, 7, 6, 2, 9];\n'+
'\n'+
'console.log(quickSort(items, 0, items.length - 1));\n';


  useEffect(() => {

  }, []);

  return (
    <main className="col-12">
            <h1 className="bd-title">Quick Sort</h1>
            <Sort method="quickSort" />

            <p className="bd-lead">Quicksort applies the divide and conquer technique as well. It works by having a pivot element such that the elements to the left of it are less than it and those to the right are greater than it. The pivot element can be any element in the array.</p>
            <h4 className="">Step-by-step guide:</h4>
            <ol>
              <li>Choose a pivot element.</li>
              <li>Partition the array such that the elements to the left of the pivot are less than it and those to the right are greater than it.</li>
              <li>Recursively apply steps 1 and 2 to the left and right partitions.</li>
              <li>Repeat steps 1-3 until the array is sorted.</li>
            </ol>
            <h4>Image representation</h4>
            <img src={image} alt="Bubble Sort" />
            <h4>JavaScript implementation</h4>
            <div className="highlight">
                <pre>
                <code>
                    {code}               
                </code>
                </pre>
            </div>


        </main>
        
  );
};

export default QuickSort;