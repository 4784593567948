import DateTime from "./DateTime";


const navLinks = [
  { title: 'Quick Sort', path: '/quick-sort' },
  { title: 'Bubble Sort', path: '/bubble-sort' },
  { title: 'Insertion Sort', path: '/insertion-sort' },
  { title: 'Selection Sort', path: '/selection-sort' },
  { title: 'Merge Sort', path: '/merge-sort' },
];

const Navigation = () => {
//   return (
//   <div>navigation</div>
// )
  
  const activePath = window.location.pathname;
  console.log(activePath)



  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <a className="navbar-brand" href="/">
        Sorting Algorithms
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarNavDropdown">
        <ul className="navbar-nav">
          {
            navLinks.map((link) => (
              <li key={link.path} className="nav-item">
                <a className={activePath === link.path ? "nav-link active" : "nav-link"} href={link.path}>{link.title}</a>
                  </li>
          ))}
        </ul>
      </div>
      <DateTime />
    </nav>
  );
};

export default Navigation;