import React, { useEffect } from 'react';

import Sort from '../../components/SortComponent/Sort';

import image from '../../assets/img/insertion-sort.png';

const InsertionSort = () => {
  const code = 'function insertionSort(arr){\n'+
'    //Start from the second element.\n'+
'    for(let i = 1; i < arr.length;i++){\n'+
'\n'+
'        //Go through the elements behind it.\n'+
'        for(let j = i - 1; j > -1; j--){\n'+
'            \n'+
'            //value comparison using ascending order.\n'+
'            if(arr[j + 1] < arr[j]){\n'+
'\n'+
'                //swap\n'+
'                [arr[j+1],arr[j]] = [arr[j],arr[j + 1]];\n'+
'\n'+
'            }\n'+
'        }\n'+
'    };\n'+
'\n'+
'  return arr;\n'+
'}\n'+
'\n'+
'console.log(insertionSort([23, 1, 10, 5, 2]));\n';


  useEffect(() => {

  }, []);

  return (
    <main className="col-12">
            <h1 className="bd-title">Insertion Sort</h1>
            <Sort method="insertionSort" />

            <p className="bd-lead">Insertion sort uses the recursion technique. There is a portion of the array that is sorted and the other that is unsorted. So you have to compare the elements from the unsorted portion one by one and insert them into the sorted portion in the correct order. In the guide below we are using ascending order.</p>
            
            <h4 className="">Step-by-step guide:</h4>
            <ol>
              <li>Start with the second element in the array.</li>
              <li>Compare it with the element to its left.</li>
              <li>If the element to its left is greater than it, swap them.</li>
              <li>Repeat steps 2 and 3 until the element is in the correct position.</li>
              <li>Repeat steps 1-4 until the array is sorted.</li>
            </ol>
            <h4>Image representation</h4>
            <img src={image} alt="Bubble Sort" />
            <h4>JavaScript implementation</h4>
            <div className="highlight">
                <pre>
                <code>
                    {code}               
                </code>
                </pre>
            </div>


        </main>
        
  );
};

export default InsertionSort;